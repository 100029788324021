<template>
  <div class="page-customer">
    <content-header :title="title" :breadList="['运营中心', '运营计划']">
      <!-- <el-button
        slot="tools"
        class="mobanku"
        round
        @click="gotoPlanTemplate"
        v-if="
          menuPermission &&
          menuPermission.运营中心 &&
          menuPermission.运营中心.create
        "
      >
        <span>从模板库创建</span>
      </el-button> -->
      <el-button
        slot="tools"
        type="primary"
        round
        @click="
          existDrawer = true;
          dialogVisible = false;
          editType = 'add';
        "
        v-if="
          menuPermission &&
          menuPermission.运营中心 &&
          menuPermission.运营中心.create
        "
      >
        <i class="el-icon-plus"></i>
        <span>新增运营计划</span>
      </el-button>
    </content-header>
    <div class="content-wrapper">
      <div class="summary">
        <div class="item" v-for="item in summary" :key="item.label">
          <div
            class="label"
            :style="{ background: item.bgcolor, color: item.color }"
          >
            {{ item.label }}
          </div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>

      <!-- 筛选条件 -->
      <div style="text-align: right">
        <img
          @click="showQuery = !showQuery"
          class="showQuery"
          src="@/assets/img/query-more.png"
          alt=""
        />
      </div>
      <div class="query-form" v-if="showQuery">
        <i class="el-icon-close" @click="showQuery = false"></i>
        <el-form :inline="true" v-if="showQuery" label-width="100px">
          <el-form-item label="计划名称:">
            <el-input
              placeholder="计划名称"
              v-model="formData.Name"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="计划类型:">
            <el-select
              v-model="formData.TriggerType"
              style="width: 200px"
              clearable
            >
              <el-option
                :value="item"
                :label="item"
                v-for="item in planmap"
                :key="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建者:">
            <el-select
              v-model="formData.CreatedById"
              style="width: 200px"
              clearable
              filterable
              :loading="userLoading"
            >
              <el-option
                :value="item._id"
                :label="item.Name"
                v-for="item in userList"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划状态:">
            <el-select v-model="formData.Status" style="width: 200px" clearable>
              <el-option
                :value="item"
                :label="item"
                v-for="item in StatusFields"
                :key="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="起止日期介于:">
            <el-date-picker
              v-model="formData.StartTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="触达渠道:">
            <el-select
              v-model="formData.FilterConditionType"
              style="width: 200px"
              clearable
            >
              <el-option
                :value="item"
                :label="item"
                v-for="(item, index) in typesChannels"
                :key="item + '_' + index"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-button size="medium" type="info" @click="handleQuery" round
            >应用</el-button
          >
        </el-form>
      </div>

      <el-table :data="tableData" v-loading="loading" class="table-customer">
        <el-table-column prop="Name" label="计划名称">
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.Status === '已发布' || scope.row.Status === '已暂停' || scope.row.Status === '已结束'"
              :to="`/plan/detail/${scope.row._id}`"
              class="g-link"
            >
              {{ scope.row.Name }}
            </router-link>
            <span v-else>{{ scope.row.Name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="TriggerType" label="计划类型">
          <template slot-scope="scope">
            <i
              :class="planIconMap[scope.row.TriggerType]"
              style="margin-right: 6px"
            ></i>
            <span>{{ scope.row.TriggerType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Status" label="计划状态">
          <template slot-scope="scope">
            <div class="status-block" :style="statusMap[scope.row.Status]">
              {{ scope.row.Status }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreatedDate" label="起止日期">
          <template slot-scope="scope">
            {{ scope.row.StartTime }} ~ {{ scope.row.EndTime }}
          </template>
        </el-table-column>
        <el-table-column prop="FilterConditionType" label="触达渠道">
          <template slot-scope="scope">
            <i
              class="iconfont icon-qudao"
              style="margin-right: 4px; vertical-align: bottom"
            ></i>
            <span>{{
              (scope.row.MessageChannelType &&
                scope.row.MessageChannelType.join &&
                scope.row.MessageChannelType.join(" | ")) ||
              "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="CreatedByUser" label="创建者">
          <template slot-scope="scope">
            <div style="display: flex">
              <div class="icon-wrap">
                <div class="first-char-user">
                  {{ scope.row.CreatedByUser.substr(0, 1) }}
                </div>
              </div>
              <span class="user-name" :title="scope.row.CreatedByUser">
                {{ scope.row.CreatedByUser }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div class="tool-wrap">
              <el-button
                type="text"
                @click="handleEdit(scope.row)"
                :disabled="
                  scope.row.Status === '已发布' ||
                  scope.row.Status === '已暂停' ||
                  scope.row.Status === '已结束'
                "
              >
                <i class="iconfont icon-edit"></i>
              </el-button>
              <el-dropdown @command="handleCommand($event, scope.row)">
                <span class="el-dropdown-link">
                  <i class="iconfont icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item
                    command="save"
                    v-if="
                      menuPermission &&
                      menuPermission.运营中心 &&
                      menuPermission.运营中心.create
                    "
                    ><i class="iconfont icon-play"></i
                    >另存为模板</el-dropdown-item
                  > -->
                  <!-- <el-dropdown-item command="publish"
                    :disabled="scope.row.Status === '已发布' || scope.row.Status === '已结束'"
                    ><i class="iconfont icon-play"></i>发布</el-dropdown-item
                  > -->
                  <el-dropdown-item
                    command="detail"
                    :disabled="
                      scope.row.Status === '草稿' ||
                      scope.row.Status === '已暂停'
                    "
                    ><i class="iconfont icon-user-doublekx"></i
                    >分析</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="pause"
                    :disabled="
                      scope.row.Status === '草稿' ||
                      scope.row.Status === '已暂停' ||
                      scope.row.Status === '已结束'
                    "
                    ><i class="iconfont icon-pause"></i>暂停</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="delete"
                    :disabled="scope.row.Status === '已发布'"
                    ><i class="iconfont icon-delete"></i>删除</el-dropdown-item
                  >
                  <el-dropdown-item command="view"
                    ><i class="el-icon-view" style="font-size: 20px"></i>查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curPlan"
      @success="handleSaved"
      :templateId="templateId"
    ></edit-drawer>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该运营计划吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="暂停确认" :visible.sync="pauseConfirmShow" width="30%">
      <span
        v-if="
          curPlan.TriggerType === '单次定时群发' ||
          curPlan.TriggerType === '单次触发'
        "
        >活动暂停后该活动的客户不再接收消息，确认是否暂停？</span
      >
      <span v-else-if="curPlan.TriggerType === '周期性定时群发'">
        活动暂停后，已启动的周期性轮次不受影响，确认是否暂停？
      </span>
      <span v-else-if="curPlan.TriggerType === '多波次流程画布'">
        活动暂停后，已经进入任一流程的客户不受影响，确认是否暂停？
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pauseConfirmShow = false" type="text"
          >取 消</el-button
        >
        <el-button type="primary" @click="handlePause" round>确 定</el-button>
      </span>
    </el-dialog>
    <save-template
      :visible.sync="saveTemplateVisible"
      :CampaignId="saveTemplateId"
      @success="successSaveTemplate"
    ></save-template>
    <!-- <el-dialog title="新增运营计划" :visible.sync="dialogVisible" width="500px">
      <div style="text-align: center">
        <el-button
          slot="tools"
          type="primary"
          round
          @click="
            existDrawer = true;
            dialogVisible = false;
            editType = 'add';
          "
        >
          <i class="el-icon-plus"></i>
          <span>新增运营计划</span>
        </el-button>
        <el-button slot="tools" type="primary" round @click="gotoPlanTemplate">
          <i class="el-icon-plus"></i>
          <span>从模板库里创建运营计划</span>
        </el-button>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span> 
    </el-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";
import {
  apiPlanSummary,
  apiGetPlanList,
  apiDeletePlan,
  apiPausePlan,
} from "@/api/plan";
import SaveTemplate from "./components/save-template.vue";
import { apiGetCampaignPermission } from "@/api/auth";
import { apiGetUserList } from "@/api/auth";

const labelBG = {
  全部: "#D7DCDF",
  草稿: "#FAECD8",
  未发布: "#F09C0A",
  已发布: "#67C23A",
  进行中: "#0483EC",
  已暂停: "#CB2E0B",
  已结束: "#747378",
};
export default {
  components: {
    EditDrawer,
    ContentHeader,
    SaveTemplate,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      summary: [],
      tableData: [],
      existDrawer: false,
      delConfirmShow: false,
      pauseConfirmShow: false,
      dialogVisible: false,
      editType: "add",
      curPlan: {},
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      planIconMap: {
        单次定时群发: "iconfont icon-clock",
        周期性定时群发: "iconfont icon-rili",
        单次触发: "iconfont icon-lightning",
        多波次流程画布: "iconfont icon-dashbaord",
      },
      statusMap: {
        草稿: "background: #FAECD8;",
        未发布: "background: #F09C0A; color: #fff;",
        进行中: "background: #0483EC; color: #fff;",
        已发布: "background: #67C23A; color: #fff;",
        已暂停: "background: #CB2E0B; color: #fff;",
        已结束: "background: #747378; color: #fff;",
      },
      saveTemplateVisible: false,
      saveTemplateId: null,
      templateId: undefined,

      showQuery: false,
      formData: {
        Name: "",
        TriggerType: "",
        CreatedById: "",
        Status: "",
        StartTime: "",
        FilterConditionType: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
      campaignStatusMapping: "common/campaign",
      typesChannels: "common/typesChannels",
    }),
    statusmap: function () {
      return Object.keys(this.statusMap);
    },
    planmap: function () {
      return Object.keys(this.planIconMap);
    },
    StatusFields() {
      const statusValue =
        this.campaignStatusMapping.find((item) => item.APIName === "Status") ||
        {};
      return statusValue.PicklistOptions || [];
    },
  },
  watch: {
    $route() {
      const query = this.$route.query;
      if (query.status === "add") {
        this.existDrawer = true;
        this.editType = "add";
      }
    },
  },
  methods: {
    getPlanSummary() {
      apiPlanSummary().then((res) => {
        const list = [];
        Object.keys(res).forEach((label) => {
          list.push({
            label: label,
            value: res[label],
            bgcolor: labelBG[label],
            color: ["全部", "草稿"].includes(label) ? "#26262C" : "#fff",
          });
        });
        this.summary = [...list];
      });
    },
    getPlanList() {
      this.loading = true;
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      if (formData.StartTime) {
        param.StartTimeLt = formData.StartTime[0] + ' 00:00:00';
        param.StartTimeRt = formData.StartTime[1] + ' 23:59:59';
        delete param.StartTime;
      }
      apiGetPlanList(param)
        .then((res) => {
          this.tableData = res.campaigns;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserList() {
      this.userLoading = true;
      const param = {
        offset: 0,
        limit: 1000,
      };
      apiGetUserList(param)
        .then((res) => {
          this.userList = res.users;
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    handleEdit(detail, show) {
      if (show) {
        this.curPlan = detail;
        this.editType = "show";
        this.existDrawer = true;
        return;
      }
      // 请求编辑权限
      apiGetCampaignPermission(detail._id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.curPlan = detail;
          this.editType = "edit";
          this.existDrawer = true;
        } else {
          this.curPlan = detail;
          this.editType = "show";
          this.existDrawer = true;
          // this.$message.error("无编辑权限");
        }
      });
    },
    handleCommand(cmd, detail) {
      switch (cmd) {
        case "detail":
          this.$router.push(`/plan/detail/${detail._id}`);
          break;
        case "delete":
          // 请求删除权限
          apiGetCampaignPermission(detail._id, {
            target: "delete",
          }).then((res) => {
            if (res.PermissionDelete) {
              this.curPlan = detail;
              this.delConfirmShow = true;
            } else {
              this.$message.error("无删除权限");
            }
          });
          break;
        case "save":
          this.saveTemplateId = detail._id;
          this.saveTemplateVisible = true;
          break;
        case "publish":
          break;
        case "pause":
          // 请求编辑权限
          apiGetCampaignPermission(detail._id, {
            target: "edit",
          }).then((res) => {
            if (res.PermissionEdit) {
              this.curPlan = detail;
              this.editType = "edit";
              this.pauseConfirmShow = true;
            } else {
              this.$message.error("无编辑权限");
            }
          });
          break;
        case "view":
          this.handleEdit(detail, true);
          break;
      }
    },
    successSaveTemplate() {
      this.saveTemplateVisible = false;
    },
    handleSaved() {
      this.existDrawer = false;
      this.getPlanSummary();
      this.getPlanList();
    },
    handleDelete() {
      apiDeletePlan(this.curPlan._id).then(() => {
        this.delConfirmShow = false;
        this.getPlanSummary();
        this.getPlanList();
      });
    },
    handlePause() {
      apiPausePlan(this.curPlan._id).then(() => {
        this.pauseConfirmShow = false;
        this.getPlanSummary();
        this.getPlanList();
      });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getPlanList();
    },
    gotoPlanTemplate() {
      this.$router.push({
        path: "/plan-template/list",
      });
    },
    handleQuery() {
      this.handlePage(1);
    },
  },
  mounted() {
    this.getPlanSummary();
    this.getPlanList();
    this.getUserList();

    const query = this.$route.query;
    if (query.status === "add") {
      this.existDrawer = true;
      this.editType = "add";
    }
    if (query.status === "templateAdd") {
      this.templateId = query.templateId;
      this.editType = "templateAdd";
      this.existDrawer = true;
    }
  },
};
</script>

<style lang="less" scoped>
.showQuery {
  cursor: pointer;
}
.query-form {
  background: #f6f7f8;
  border-radius: 6px;
  position: relative;
  padding: 30px 90px 6px 16px;
  margin: 0 0 20px 0;
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  /deep/ .el-button--info {
    position: absolute;
    right: 10px;
    bottom: 16px;
    background: #4d4b58;
  }
}
.page-customer {
  .table-customer {
    margin-top: 0;
    /deep/.el-table__row:nth-child(3n + 1) {
      .first-char-user {
        background: #0483ec;
      }
    }
    /deep/.el-table__row:nth-child(3n + 2) {
      .first-char-user {
        background: #44c5b8;
      }
    }
    /deep/.el-table__row:nth-child(3n + 3) {
      .first-char-user {
        background: #f09c0a;
      }
    }
  }
  .mobanku {
    border: 1px solid #66b1ff;
    color: #66b1ff;
  }
  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
  .summary {
    display: flex;
    .item {
      margin-right: 76px;
      text-align: center;
    }
    .label {
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-bottom: 4px;
      padding: 0 7px;
      background: #d7dcdf;
      border-radius: 20px;
    }
    .value {
      font-family: Noto Sans Display;
      font-size: 48px;
      font-weight: 300;
      line-height: 56px;
      color: #747378;
    }
  }
  .status-block {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    border-radius: 20px;
    padding: 0 7px;
    font-size: 12px;
  }
  .user-name {
    margin-left: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
  }
  .icon-wrap {
    position: absolute;
    top: 50%;
    margin-top: -18px;
    .first-char,
    .first-char-user,
    .icon-user {
      position: absolute;
      border-radius: 50%;
      text-align: center;
    }
    .first-char,
    .first-char-user {
      width: 37px;
      height: 37px;
      line-height: 34px;
      left: 0;
      border: 1px solid #ffffff;
      background: #747378;
      font-size: 16;
      color: #fff;
      z-index: 1;
    }
    .icon-user {
      width: 36px;
      height: 36px;
      line-height: 36px;
      top: 1px;
      left: 15px;
      background: #e2e6e9;
      i {
        font-size: 18px;
        color: #4d4b58;
      }
    }
  }
}
</style>
